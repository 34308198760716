import styles from './CoverSearchbox.module.scss';
import { useInstantSearch } from 'react-instantsearch-hooks-web';

export const SearchStatistics = () => {
  const { results, status } = useInstantSearch();

  console.log('🚀 ~ file: SearchStatistics.tsx:6 ~ SearchStatistics ~ results:', results);
  console.log('🚀 ~ file: SearchStatistics.tsx:6 ~ SearchStatistics ~ status:', status);

  return (
    <div className={styles.searchStatistics}>
      <span>
        {results.nbHits} results in {results.processingTimeMS}ms
      </span>
    </div>
  );
};
