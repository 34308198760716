import React, { FC } from 'react';
import { Carousel, Grid } from 'antd';
import { LeftOutlined, RightOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { CustomArrowProps } from '@ant-design/react-slick';
import { ChecklistCard } from '../../ChecklistCard';
import styles from './checklist.module.scss';
import usePublishableChecklists from 'services/hooks/queries/usePublishableChecklists';
import { Checklist } from 'services';

const SlickArrowLeft: FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;

  return (
    <button type="button" className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <LeftOutlined />
    </button>
  );
};

const SlickArrowRight: FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      type="button"
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    >
      <RightOutlined />
    </button>
  );
};

const carouselOptions = {
  dots: false,
  infinite: false,
  draggable: true,
  arrows: true,
  speed: 400,
  slidesToShow: 4,
  swipeToSlide: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  responsive: [
    {
      breakpoint: 1599,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const ChecklistSection: FC = () => {
  const theBoatAppBaseUrl = process.env.THEBOATAPP_URL;
  const { data: publicChecklists } = usePublishableChecklists({ first: 10 });

  const { useBreakpoint } = Grid;

  const { sm } = useBreakpoint();

  return (
    <section className={styles.container}>
      <div className={styles.container__content}>
        <div className={styles.content__badge}>New</div>
        <h2 className={styles.content__title}>
          Checklists from TheBoatApp community. Proven workflows to get things right!
        </h2>
        <p className={styles.content__text}>
          A wide variety of checklists, available for you to pick and use out of the box! You like one, simply follow it
          and always have the latest version. You want to customize one, simply copy it and edit away to your liking!
        </p>
      </div>
      {!sm ? (
        <div>
          {publicChecklists?.publishableChecklists?.data.map((checklist) => (
            <ChecklistCard checklist={checklist as Checklist} key={checklist.id} />
          ))}
        </div>
      ) : (
        <Carousel {...carouselOptions} className="checklist__carousel">
          {publicChecklists?.publishableChecklists?.data.map((checklist) => (
            <ChecklistCard checklist={checklist as Checklist} key={checklist?.id} />
          ))}
        </Carousel>
      )}

      <a href={`${theBoatAppBaseUrl}/checklists`} target="_blank" className={styles.container__button} rel="noreferrer">
        Browse checklist <ArrowRightOutlined />
      </a>
    </section>
  );
};

export { ChecklistSection };
